


























































































































































































































import Vue from 'vue'
import Layout from "@/router/layouts/main.vue";
import { mapActions, mapGetters } from "vuex";
import {required, minValue} from "vuelidate/lib/validators";
import MyCurrencyInput from "@/components/myCurrencyInput.vue"
import i18n from "@/i18n/i18n";
import { SubscriptionDescriptionsData } from "@/types";
import { errorMessage, successMessage } from "@/utils/messages-handlers";
export default Vue.extend({
    components: {
        Layout,
        // "my-currency-input" :MyCurrencyInput,
    },
    data() {
        return {
            addAdditionalService: false,
            basicMonthly: {},
            basicYearly: {},
            basicPrice: '',
            premiumMonthly: {},
            premiumYearly: {},
            premiumPrice: '',
            additionalServicesMonthly: {},
            additionalServicesYearly: {},
            activeDescriptions: {},
            trialingPeriod: {},
            subDescriptions: {},
            editedTrialPeriod: '',
            additionalServicesCheckbox: [],

            newAdditionalServiceNameEn: '',
            newAdditionalServicePrice: '',
            additionalServicesError: false,
            isAddNewServiceSubmitted: false,
            description: "",


          options:[
            {title: 'Month'},
            {title: 'Year'},
          ],
          selected: 'Month',
        }
    },

  //@ts-ignore
  validations:{
    description: {required},
    newAdditionalServiceNameEn: {required},
    newAdditionalServicePrice: {required, minValue: minValue(1)},
  },

  async created() {
    await this.setTaxPricePeriodTranslation();
    await this.setProductsForEdit();
    await this.setTrialPeriodForEdit();
    await this.setSubscriptionDescrtiptionsForEdit();
    this.setBasicPrice();
    this.setPremiumPrice();
  },

  computed: {
    ...mapGetters("azTrialPeriodModule", {
        trialPeriod: "GET_TRIAL_PERIOD"
      }),
    ...mapGetters("azProductsModule", {
      productBasicMonth: "GET_PRODUCT_BASIC_MONTH",
      productBasicYear: "GET_PRODUCT_BASIC_YEAR",
      productPremiumMonth: "GET_PRODUCT_PREMIUM_MONTH",
      productPremiumYear: "GET_PRODUCT_PREMIUM_YEAR",
      additionalServicesMonth: "GET_ADDITIONAL_SERVICES_MONTH",
      additionalServicesYear: "GET_ADDITIONAL_SERVICES_YEAR",
    }),
    ...mapGetters("azSubscriptionDescriptionsModule", {
      subscriptionDescriptions: "GET_SUBSCRIPTION_DESCRIPTIONS",
      activeSubscriptionDescriptions: "GET_ACTIVE_SUBSCRIPTION_DESCRIPTIONS"
    }),
    ...mapGetters("azTaxPricePeriodTranslationModule", {
      taxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    getBasicPrice():number{
      return this.selected == 'Month' ? this.basicMonthly.unitAmount : this.basicYearly.unitAmount;
    },
    getPremiumPrice():number{
        return this.selected == 'Month' ? this.premiumMonthly.unitAmount : this.premiumYearly.unitAmount;
    },
    additionalProducts(){
      return this.selected == "Month" ? this.additionalServicesMonth : this.additionalServicesYear;
    },
    locale(): string {
      return i18n.locale;
    },
    translatedMonthPriceInfo(){
      if(!this.taxPricePeriodTranslation[0]) return;
      return i18n.locale == 'en' ? this.taxPricePeriodTranslation[0].priceAndPeriodMonthEn : this.taxPricePeriodTranslation[0].priceAndPeriodMonthSk;
    },
    translatedYearPriceInfo(){
      if(!this.taxPricePeriodTranslation[0]) return;
      return i18n.locale == 'en' ? this.taxPricePeriodTranslation[0].priceAndPeriodYearEn : this.taxPricePeriodTranslation[0].priceAndPeriodYearSk;
    },
  },
  methods:{
    ...mapActions("azTrialPeriodModule", {
      setTrialPeriod: "FETCH_TRIAL_PERIOD",
      updateTrialPeriod: "UPDATE_TRIAL_PERIOD",
    }),
    ...mapActions("azProductsModule", {
      setProducts: "FETCH_PRODUCTS",
      createNewProduct: "CREATE_NEW_PRODUCT",
      updateProductPrice: "UPDATE_PRODUCT_PRICE",
      updateAdditionalServiceNameAndPrice: "UPDATE_ADDITIONAL_SERVICE_NAME_AND_PRICE"
    }),
    ...mapActions("azSubscriptionDescriptionsModule", {
      setSubscriptionDescriptions: "FETCH_SUBSCRIPTION_DESCRIPTIONS",
      createSubscriptionDescription: "CREATE_SUBSCRIPTION_DESCRIPTION",
      attachSubscriptionDescription: "ATTACH_SUBSCRIPTION_DESCRIPITON",
      detachSubscriptionDescription: "DETACH_SUBSCRIPTION_DESCRIPTION",
      softDeleteSubscriptionDescription: "SOFT_DELETE_SUBSCRIPTION_DESCRIPTION",
      azUpdateSubscriptionDescriptionAndStripe: "UPDATE_SUBSCRIPTION_DESCRIPTION",
    }),
    ...mapActions("azTaxPricePeriodTranslationModule", {
      setTaxPricePeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION"
    }),
    setBasicPrice(){
      this.basicPrice = this.selected == 'Month' ? this.basicMonthly.unitAmount : this.basicYearly.unitAmount;
    },
    setPremiumPrice(){
      this.premiumPrice = this.selected == 'Month' ? this.premiumMonthly.unitAmount : this.premiumYearly.unitAmount;
    },
    toggleAdditionalService(){
      this.addAdditionalService=!this.addAdditionalService
      this.$v.$reset();
    },
    async setProductsForEdit(){
      await this.setProducts();
      this.basicMonthly = await this.productBasicMonth[0]
      this.basicYearly = await this.productBasicYear[0]
      this.premiumMonthly = await this.productPremiumMonth[0]
      this.premiumYearly = await this.productPremiumYear[0]
      this.additionalServicesMonthly = await this.additionalServicesMonth
      this.additionalServicesYearly = await this.additionalServicesYear
    },
    async setTrialPeriodForEdit(){
      await this.setTrialPeriod();
      this.trialingPeriod = await this.trialPeriod[0]
    },
    async setSubscriptionDescrtiptionsForEdit(){
      await this.setSubscriptionDescriptions();
      this.subDescriptions = this.activeSubscriptionDescriptions
      this.activeDescriptions = this.activeSubscriptionDescriptions
    },
    async addNewSubscriptionDescriptions(){

      this.$v.$touch();
      if(this.$v.description.$invalid) return;
      const newSubDescriptionPayload: object = {
          descriptionEn: this.description,
          descriptionSk: null,
          isTranslationCompleted: false,
      };
      await this.createSubscriptionDescription(newSubDescriptionPayload)
      this.setSubscriptionDescrtiptionsForEdit()
      this.description = ''
    },
    check: async function(subscripitonDescriptionId, songorooPackageTypeId ,e) {
      const payload: object = {
        descriptionId: subscripitonDescriptionId,
        songorooPackageType: songorooPackageTypeId,
      }
      if(e){
        await this.attachSubscriptionDescription(payload)
      }else{
        if(songorooPackageTypeId == 2){
          if (this.productBasicMonth[0].subscriptionDescriptions.length > this.productPremiumMonth[0].subscriptionDescriptions.length-1){
            this.
            errorMessage("Premium subscriptions count cannot be less than Basic descriptions count", 5000)
            return;
          }
          await this.detachSubscriptionDescription(payload)
        }else {
          await this.detachSubscriptionDescription(payload)
        }
      }
    },
    isChecked(subscriptionDescriptionId: number, songorooPackageType: number){
        const subscriptionDescriptions = songorooPackageType == 1 ? this.productBasicMonth[0].subscriptionDescriptions:this.productPremiumMonth[0].subscriptionDescriptions;
        if(subscriptionDescriptions != null){
           return subscriptionDescriptions.some(el => el.id == subscriptionDescriptionId)
        }else {
        return false
        }
    },
    isPremiumDescriptionButtonDisabled(subscriptionDescriptionId: number, songorooPackageType: number){
      if (this.productBasicMonth[0].subscriptionDescriptions.length > this.productPremiumMonth[0].subscriptionDescriptions.length-1 && this.isChecked(subscriptionDescriptionId, 2)){
        return true
      }else{
        return false
      }
    },
    isBasicDescriptionButtinDisabled(subscriptionDescriptionId: number, songorooPackageType: number){
      if (
        this.productBasicMonth[0].subscriptionDescriptions.length+1 > this.productPremiumMonth[0].subscriptionDescriptions.length
        && !this.isChecked(subscriptionDescriptionId, 1)
        || !this.isChecked(subscriptionDescriptionId, 2)
        ){
        return true
      }else{
        return false
      }
    },
    async softDelete(subscriptionDescriptionId: number){
      const payload: object = {
        deletedAt: Math.floor(Date.now() / 1000)
      }
      await this.softDeleteSubscriptionDescription({resourceId: subscriptionDescriptionId, payload: payload})
      await this.setSubscriptionDescrtiptionsForEdit()
    },
    async updatePriceAndSubscriptionDescription(){
      // if(this.newAdditionalServicePrice != '' && this.newAdditionalServiceNameEn != '') {
      //   errorMessage('Unsaved Additional service. Please add service or delete fields', 5000)
      //   this.additionalServicesError = true
      //   return
      // }
      this.additionalServicesError = false

      if(this.editedTrialPeriod && this.editedTrialPeriod != this.trialPeriod[0].freeTrialPeriod){
        const updateTrialPeriodPayload = {
          'freeTrialPeriod': Number (this.editedTrialPeriod)
        };
        await this.updateTrialPeriod(updateTrialPeriodPayload)
      }
      if(this.basicPrice && this.basicPrice != this.getBasicPrice){
        if(this.selected == "Month"){
          const productId = this.basicMonthly.id;
          const payload = {
            'unitAmount': Number (this.basicPrice)
          };
          await this.updateProductPrice({productId: productId, payload: payload, songorooPackageType:1})
        } else {
          const productId = this.basicYearly.id;
          const payload = {
            'unitAmount': Number (this.basicPrice)
          };
          await this.updateProductPrice({productId: productId, payload: payload, songorooPackageType: 1})
        }
      }
      if (this.premiumPrice && this.premiumPrice != this.getPremiumPrice){
        if(this.selected == "Month"){
          const productId = this.premiumMonthly.id;
          const payload = {
            'unitAmount': Number (this.premiumPrice)
          };
          await this.updateProductPrice({productId: productId, payload: payload, songorooPackageType: 2})
        } else {
          const productId = this.premiumYearly.id;
          const payload = {
            'unitAmount': Number (this.premiumPrice)
          };
          await this.updateProductPrice({productId: productId, payload: payload, songorooPackageType: 2})
        }
      }

      if(this.additionalServicesCheckbox){
        for (let checkboxIndex in this.additionalServicesCheckbox) {
          if(this.selected == 'Month'){
            if(!this.additionalServicesMonthly.length) return;
            const productId = this.additionalServicesMonthly[checkboxIndex].id;
            const unitAmount = this.additionalServicesMonthly[checkboxIndex].unitAmount;
            const updateMonthlyAdditionalServicePayload = {
              'productName': this.additionalServicesMonthly[checkboxIndex].productName,
              'isActive': this.additionalServicesMonthly[checkboxIndex].isActive,
            };
            await this.updateAdditionalServiceNameAndPrice({productId: productId, unitAmount: unitAmount, payload: updateMonthlyAdditionalServicePayload })
          }else if(this.selected == "Year"){
            if(!this.additionalServicesYearly.length) return;
            const productId = this.additionalServicesYearly[checkboxIndex].id;
            const unitAmount = this.additionalServicesYearly[checkboxIndex].unitAmount;
            const updateMonthlyAdditionalServicePayload = {
              'productName': this.additionalServicesYearly[checkboxIndex].productName,
              'isActive': this.additionalServicesYearly[checkboxIndex].isActive,
            };
            await this.updateAdditionalServiceNameAndPrice({productId: productId, unitAmount: unitAmount, payload: updateMonthlyAdditionalServicePayload })
          }
        }
        successMessage("Additional services updated", 3000);
      }

      await this.azUpdateSubscriptionDescriptionAndStripe(this.subDescriptions)
      this.$router.push('/subscription-plans')
    },
    async saveAdditionalService(){
      this.isAddNewServiceSubmitted = true;
      this.$v.$touch();

      if(this.$v.newAdditionalServiceNameEn.$invalid || this.$v.newAdditionalServicePrice.$invalid) return;
      const newAdditionalServicePayload = {
        'songorooServiceType': 2,
        'songorooPackageType': null,
        'productName': this.newAdditionalServiceNameEn,
        'productNameSk': null,
        'unitAmount': Number (this.newAdditionalServicePrice),
        'recurringInterval': this.selected == "Month" ? "month" : "year"
      };

      await this.createNewProduct(newAdditionalServicePayload)
      await this.setProductsForEdit()
      this.newAdditionalServicePrice = '';
      this.newAdditionalServiceNameEn = '';
      this.isAddNewServiceSubmitted = false;
    },
  },

  watch:{
      selected(value) {
      this.setBasicPrice()
      this.setPremiumPrice()
    }
  }
})
